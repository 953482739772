<template>
  <!-- Roles -->
  <b-container fluid>
    <Nav></Nav>
    <b-row no-gutters>
      <b-col>
        <div class="card shadow-sm mt-2 mb-2">
          <div class="card-body">
            <h5 class="card-title font-weight-bold">Bar</h5>
            <p class="small">Example Roles: Bartender, Server.</p>
            <router-link
              class="btn btn-sm font-weight-bold primary-blue"
              to="/roles/fulfiller/"
              >Select</router-link
            >
          </div>
        </div>
        <div class="card shadow-sm mt-2 mb-2">
          <div class="card-body">
            <h5 class="card-title font-weight-bold">Kitchen</h5>
            <p class="small">Example Roles: Chef, cook.</p>
            <router-link
              class="btn btn-sm font-weight-bold primary-blue"
              to="/roles/fulfiller/"
              >Select</router-link
            >
          </div>
        </div>
        <div class="card shadow-sm mt-2 mb-2">
          <div class="card-body">
            <h5 class="card-title font-weight-bold">Runner</h5>
            <p class="small">
              Example Roles: Host, Server, Busser, Manager, Support Staff.
            </p>
            <router-link
              class="btn btn-sm font-weight-bold primary-blue"
              to="/roles/runner/"
              >Select</router-link
            >
          </div>
        </div>
        <div class="card shadow-sm mt-2 mb-3" v-if="$store.state.user.is_owner">
          <div class="card-body">
            <h5 class="card-title font-weight-bold">Operations</h5>
            <p class="small">
              Example Roles: Owner, Accounting, Operations Manager.
            </p>
            <router-link
              class="btn btn-sm font-weight-bold primary-blue"
              to="/roles/owner/"
              >Select</router-link
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Nav from "../components/Nav.vue";

export default {
  name: "Roles",
  components: {
    Nav,
  },
};
</script>
